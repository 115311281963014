.carouselCard {
	max-width: 750px;
	width: 100%;
}

.carouselContainer {
 width: 100%;

}


.image-gallery-thumbnail img {
	
	height: 80px;
	object-fit: contain;
}

.image-gallery-slide img {
	
	height: 500px;
	width:;
	object-fit: scale-down;
}

.image-gallery .fullscreen img {
  width: 100%;
  height: auto;
} 