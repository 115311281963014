.aboutContainer {
  width: 100%;
}

.aboutCard {
 max-width: 900px;
}

.youtubeText {
  max-width: 900px;
  height: auto;
}


