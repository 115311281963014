.background {
  background-image: url(background-min.jpg);
  background-size: cover;
  background-position: center center;
  position: fixed;
  top: 0px; /* Header Height */
  bottom: 0px; /* Footer Height */
  left: 0px;
  right: 0px;
}

.backgroundOverlay {
  background-color: rgba(242, 242, 242, 0.3);
  position: absolute;
  top: 0px; /* Header Height */
  bottom: 0px; /* Footer Height */
  left: 0px;
  right: 0px;
}

.backgroundOverlay2 {
  background-color: rgba(42, 42, 42, 0.7);
  position: absolute;
  top: 0px; /* Header Height */
  bottom: 0px; /* Footer Height */
  left: 0px;
  right: 0px;
}
/*.mailchimp {

  position: absolute;
  top: 0px;
  right: 0px;
}*/

.allNav {
  margin-left: 10%;
  /*  position: relative;
*/
}

.logo {
  
  margin-top: 0px;
  margin-right: 60px;
  width: 60%;
  max-width: 800px;
  min-width: 500px;
 
}

.nav {
  width: 60%;
  max-width: 900px;
  min-width: 500px;
}

/*check app css for sizing*/
.navButton {
  background-color: rgba(242, 242, 242, 0.6);
  z-index: +1;
  width: 100px;
  height: 35px;
}

.contentContainer {
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  bottom: 35px;
  top: 53px;
  /*  left: 10%;*/
  overflow: scroll;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.cardHeader {
  background-color: rgba(32, 28, 29, 0.9);
}

/*.cardBG {
  background-color: rgba(135, 150, 160, 0.8);
}*/

.cardBG {
  background-color: rgba(42, 42, 42, 0.7);
}

.social {
  position: relative;
  right: 8%;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  max-width: 590px;
  min-width: 450px;
  margin-bottom: 15px;
}

@media only screen and (max-width: 600px) {
  .allNav {
    margin-left: 0px;
  }
  .contentContainer {
    left: 0;
  }
  .social {
    right: 0;
  }
}

p {
  text-align: justify;
  text-justify: inter-word;
}
