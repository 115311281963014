@font-face {
  font-family: 'Ubuntu-Regular';
  src: local('Ubuntu-Regular'),
    url(./fonts/Ubuntu-Regular.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: 'Ubuntu-Regular';
  background-color: rgba(242, 242, 242, 0.6);
  text-size-adjust: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
