.GigDates {
  position: absolute;
  bottom: 10px;
  top: 60px;
  left: 10%;
  overflow: scroll;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
