.datesBackground {
  background-color: rgba(135, 150, 160, 0.8);
}

.cardText {
  max-width: 30rem;
}

.cardImage {
  max-width: 30rem;
}


@media screen and (max-width: 67rem) {
  .cardText {
    max-width: 33rem;
  }
  .card {
    max-width: 30rem;

  }
}
