.formContainer {
	width: 100%;
}

.contactCard {
	height: auto;
	max-width: 900px;
}

.loading {
	z-index: +1;
	background-color: rgba(242, 242, 242, 0.9);
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;

	top: 0px;
	bottom: 0px;
}

form {
	max-width: 50rem;
}

textarea {
	resize: none;
	width: 100%;
	height: 15rem;
	white-space: pre-wrap;
}